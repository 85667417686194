import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import EquipmentResource from 'api/equipment';
import { CenterSpinner } from 'components/common/CenterSpinner';
import EquipmentForm from 'components/equipment/EquipmentForm';
import routes from 'constants/routes';
import { EquipmentSchema } from 'interface/equipment/equipmentSchema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

const EditEquipment: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<EquipmentSchema>();

  const api = new EquipmentResource();

  const equipmentQuery = useQuery(
    [`equipment${id}-edit`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateEquipment = useMutation((data: any) => api.update(id, data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Equipment has been updated.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('equipmentList');
      navigate(routes.manage.equipments.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Equipment Update Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      name: checkNullOrEmpty(data?.name),
      description: checkNullOrEmpty(data?.description),
      code: checkNullOrEmpty(data?.code),
      equipment_class: data?.equipment_class?.value,
      // optional: data?.optional?.value,
      owner_or_rent: data?.owner_or_rent?.value,
      owner: data?.owner,
      location: checkNullOrEmpty(data?.location),
      party: checkNullOrEmpty(data?.party),
      wet_rate: checkNullOrEmpty(data?.wet_rate),
      dry_rate: checkNullOrEmpty(data?.dry_rate),
      beginning_date: DateFormatMDY(checkNullOrEmpty(data?.beginning_date)),
      end_date: DateFormatMDY(checkNullOrEmpty(data?.end_date)),
      serial_number: checkNullOrEmpty(data?.serial_number),
      fuel_per_hour: checkNullOrEmpty(data?.fuel_per_hour),
      monthly_rental: checkNullOrEmpty(data?.monthly_rental),
      budgeted_operating_hours: checkNullOrEmpty(
        data?.budgeted_operating_hours
      ),
      budgeted_idle_hours_month: checkNullOrEmpty(
        data?.budgeted_idle_hours_month
      ),
      note: data?.note,
      plant: data?.plant?.value,
      pit: data?.pit?.value,
      cost: data.cost,
      wear_per_hour_insurance: data.wear_per_hour_insurance,
      yard_accural: data.yard_accural,
      r_m_per_hour: data.r_m_per_hour,
      operated_status: data?.operated_status?.value,
    };
    updateEquipment.mutate(formData);
  };

  const checkNullOrEmpty = (input: any) => {
    if (input?.trim()?.length > 0) {
      return input;
    }
    return undefined;
  };

  if (equipmentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (equipmentQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {'Equipment Not Found.'}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">{'Edit Equipment'}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateEquipment.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <EquipmentForm equipmentData={equipmentQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateEquipment.isLoading}>
                  Update
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditEquipment;
